import request, { IResponseList, IResponseEntity } from '@/api/request'
import { CONFIG_ELEMENT_TYPES, IConfigElement } from '@/models/preset'
import { IPresetConfig } from '@/models/entities/presetConfig'

export const createConfig = (data: IConfigElement<CONFIG_ELEMENT_TYPES>) => {
  return request({
    url: '/api/v1/preset/config',
    method: 'POST',
    data,
  })
}

export const getConfig = (data?: unknown) => {
  return request<IResponseEntity<IConfigElement<CONFIG_ELEMENT_TYPES>>>({
    url: `/api/v1/preset/config`,
    method: 'GET',
    params: data,
  })
}

export const getConfigs = (data?: unknown) => {
  return request<IResponseList<IResponseEntity<IPresetConfig>>>({
    url: `/api/v1/preset/configs`,
    method: 'GET',
    params: data,
  })
}

export const overrideConfig = (data: IConfigElement<CONFIG_ELEMENT_TYPES>) => {
  return request({
    url: '/api/v1/preset/config/overrides',
    method: 'POST',
    data,
  })
}
