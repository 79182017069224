export interface IGetIdentificationExceptionsResponse {
  list: IIdentificationExceptionResponse[]
  filters: null
  count: number
}

export interface IIdentificationExceptionResponse {
  id: number
  contourId: number
  payerCode1: string
  payerCode2: string
  benefitSubjectId: string
  note: string
}

export enum RULE_TYPE_ID {
  // eslint-disable-next-line no-unused-vars
  UNIDENTIFIED = 1,
  // eslint-disable-next-line no-unused-vars
  IDENTIFY_SUBJECT,
}

export enum RULE_TYPE_NAME {
  // eslint-disable-next-line no-unused-vars
  UNIDENTIFIED = 'Не идентифицировать',
  // eslint-disable-next-line no-unused-vars
  IDENTIFY_SUBJECT = 'Идентифицировать на другого субъекта',
}

export interface IIdentificationExceptionsRuleType {
  id: number
  name: string
}

export interface ICreateIdentificationExceptionData {
  contourId: number
  payerCode1: string
  payerCode2?: string
  benefitSubjectId?: string
  note?: string
}

export interface ICreateIdentificationExceptionResponse {
  data: {
    benefitSubjectId: string
    contourId: number
    id: number
    note: string
    payerCode1: string
    payerCode2: string
  }
}

export interface IEditIdentificationExceptionData extends ICreateIdentificationExceptionData {}
export interface IEditIdentificationExceptionResponse
  extends ICreateIdentificationExceptionResponse {}
