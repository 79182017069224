export default [
  {
    id: 3,
    name: 'выставки',
    shortName: 'ВЗ',
  },
  {
    id: 1,
    name: 'АЗС',
    shortName: 'АЗС',
  },
  {
    id: 4,
    name: 'гостиницы',
    shortName: 'ГЦ',
  },
]
