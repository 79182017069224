import { CONFIG_ELEMENT_TYPES, IConfigElement } from '@/models/preset'
import {
  IIdentificationExceptionsRuleType,
  RULE_TYPE_ID,
  RULE_TYPE_NAME,
} from '@/api/payment/models/identificationExceptions'

export const tableConfig: IConfigElement<CONFIG_ELEMENT_TYPES.TablePreset> = {
  id: '1',
  name: 'Таблица платежей',
  type: 'TablePreset',
  widgetId: 'paymentsRegistry_table',
  dbField: null,
  dataFieldPath: null,
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
    presetName: null,
    presetParams: null,
  },
  children: [
    {
      id: '2',
      name: 'колонка Номер п/п',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_docNumber',
      dbField: null,
      dataFieldPath: 'docNumber',
      prefs: {
        label: 'Номер п/п',
        hidden: 'true',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          sort: 'default',
          filter: {
            label: 'Введите',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.docNumber.value',
            queryOperator: {
              field: 'filters.docNumber.op',
              value: 'contains',
            },
            debounce: true,
          },
          width: '80',
        },
      },
      children: [],
    },
    {
      id: '8',
      name: 'колонка Остаток',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_paymentRestSum',
      dbField: null,
      dataFieldPath: 'payment.restSum',
      prefs: {
        label: 'ОстатокFFFFFFFFFFFFFFF',
        hidden: 'true',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnSumPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Введите',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.paymentRestSum.value',
            queryOperator: {
              field: 'filters.paymentRestSum.op',
              value: 'eq',
            },
            debounce: true,
          },
          width: '80',
          align: 'right',
        },
      },
      children: [],
    },
    {
      id: '3',
      name: 'колонка Дата п/п',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_docDate',
      dbField: null,
      dataFieldPath: 'docDate.value',
      prefs: {
        label: 'Дата п/п',
        hidden: 'true',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDatePreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Выберите',
            presetName: 'table.columns.filters.PresetTableColumnFilterDateRange',
            field: 'filters.docDate.value',
            queryOperator: {
              field: 'filters.docDate.op',
              value: ['gte', 'lte'],
            },
            debounce: true,
          },
          width: '70',
        },
      },
      children: [],
    },
  ],
}

export const identificationExceptionsRuleTypeList: IIdentificationExceptionsRuleType[] = [
  {
    id: RULE_TYPE_ID.IDENTIFY_SUBJECT,
    name: RULE_TYPE_NAME.IDENTIFY_SUBJECT,
  },
  {
    id: RULE_TYPE_ID.UNIDENTIFIED,
    name: RULE_TYPE_NAME.UNIDENTIFIED,
  },
]
