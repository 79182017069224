import request, { IResponseList } from '@/api/request'
import { tableConfig } from './mock'
import { IUsecategory, IUsecategoryListParams } from '@/models/entities/usecategoies'

export const getTableConfig = () => Promise.resolve(tableConfig)

export const getUsecategoriesList = (params?: IUsecategoryListParams) => {
  return request<IResponseList<IUsecategory>>({
    url: `/api/v1/usecategory/usecategories`,
    method: 'GET',
    params,
  })
}

export const getBaseUsecategoriesList = data => {
  return request({
    url: `/api/v1/usecategory/base_usecategories`,
    method: 'GET',
    params: data,
  })
}
