export const tableConfig = {
  columns: [
    {
      field: 'name',
      label: 'Наименование',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '50%',
        },
      },
    },
    {
      field: 'importType.name',
      label: 'Тип загрузки',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '50%',
        },
      },
    },
    {
      field: 'isDefault',
      label: 'По умолчанию',
      preset: 'table.columns.TableColumnIconPreset',
      presetParams: {
        options: {
          width: '120',
          align: 'center',
        },
      },
    },
    {
      field: '',
      label: 'Действия',
      preset: 'table.columns.TableColumnControlsPreset',
      presetParams: {
        options: {
          width: '120',
          rowControls: {
            isEditable: 'true',
            isDeletable: 'true',
          },
        },
      },
    },
  ],
}

export const errorRequiresAttentionFilterValues = {
  list: [
    {
      id: 1,
      name: 'Есть проблемы',
      value: true,
    },
    {
      id: 2,
      name: 'Нет проблем',
      value: false,
    },
  ],
}
