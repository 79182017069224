import { IUseobject } from '@/models/entities/useobject'
import { Ref } from 'vue'
import { IOptions } from '@/utils/mappers'

export function prepareObject(
  response: IUseobject,
  typesForSelect: Ref<IOptions[]>,
  classNames: Record<number, string>,
) {
  return {
    id: response.id,
    usecategory: response.usecategory,
    regionalCoefficient: '',
    subject: response.subject?.displayName || '',
    network: '-',
    groups: response.groups.map(el => el.name).join(', ') || '',
    contacts: '-',
    note: response.note,
    financialCharacteristics: '-',
    type: typesForSelect.value.find(el => el.value === response.typeId)?.label || '',
    class: Number(response.classId) || '',
    className: classNames[Number(response.classId)] || '',
    name: response.name || '',
    fullAddress: response?.address?.fullAddress || '',
    parent: {
      id: response?.parent?.id || '',
      name: response?.parent?.name || '',
    },
    createdAt: new Date(response.createdAt).toLocaleDateString('ru-RU'),
    createdBy: {
      id: response?.createdBy?.id || '',
      name: response?.createdBy?.name || '',
    },
    geo:
      response?.address?.geoLat && response?.address?.geoLon
        ? `${response?.address?.geoLat}, ${response?.address?.geoLon}`
        : '',
    license: {
      number: response?.license?.number || '',
      dateBegin: response?.license?.dateBeg
        ? new Date(response?.license?.dateBeg.value).toLocaleDateString('ru-RU')
        : '',
      dateEnd: response?.license?.dateEnd
        ? new Date(response?.license?.dateEnd.value).toLocaleDateString('ru-RU')
        : '',
      licenseTerritory: response?.license?.licenseTerritory || '',
      volume: response?.license?.volume || '',
      musicPercent: response?.license?.musicPercent || '',
    },
  }
}
