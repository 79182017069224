import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  IUser,
  ISystemRole,
  ICreateSystemRoleData,
  IEditSystemRoleData,
  IUserRole,
  ICreateUserRoleData,
  IEditUserRoleData,
} from '@/models/entities/user'
import { IContoursRoles } from '@/models/entities/contour'

export const getAvailableContourRoles = () => {
  return request<IResponseList<IContoursRoles>>({
    url: `/api/v1/users/available_contour_roles`,
    method: 'GET',
  })
}

export const confirmUser = data => {
  return request({
    url: `/api/v1/user/users/confirmation`,
    method: 'POST',
    data,
  })
}

export const getUsers = (params?: unknown) =>
  request<IResponseList<IUser>>({
    url: `/api/v1/users`,
    params,
  })

export const getUser = (id: IUser['id']) =>
  request<IResponseEntity<IUser>>({
    url: `/api/v1/users/${id}`,
  })

export const createUser = data => {
  return request<IResponseEntity<IUser>>({
    url: `/api/v1/user/users`,
    method: 'POST',
    data,
  })
}

export const getSystemRoles = () => {
  return request<IResponseList<ISystemRole>>({
    url: `/api/v1/user/system_roles`,
    method: 'GET',
  })
}

export const getSystemRole = (id: string) => {
  return request<IResponseEntity<ISystemRole>>({
    url: `/api/v1/user/system_roles/${id}`,
    method: 'GET',
  })
}

export const createSystemRole = (data: ICreateSystemRoleData) => {
  return request({
    url: `/api/v1/user/system_roles`,
    method: 'POST',
    data,
  })
}

export const editSystemRole = (id: string, data: IEditSystemRoleData) => {
  return request({
    url: `/api/v1/user/system_roles/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteSystemRole = (id: string) => {
  return request({
    url: `/api/v1/user/system_roles/${id}`,
    method: 'DELETE',
  })
}

export const getUserRoles = (params?: unknown) => {
  return request<IResponseList<IUserRole>>({
    url: `/api/v1/user/user_roles/search`,
    method: 'GET',
    params,
  })
}

export const createUserRole = (data: ICreateUserRoleData) => {
  return request({
    url: `/api/v1/user/user_roles`,
    method: 'POST',
    data,
  })
}

export const editUserRole = (id: string, data: IEditUserRoleData) => {
  return request({
    url: `/api/v1/user/user_roles/${id}`,
    method: 'PUT',
    data,
  })
}
