import request, { IResponseList } from '@/api/request'
import { identificationExceptionsRuleTypeList } from '@/api/payment/mock'
import {
  ICreateIdentificationExceptionData,
  ICreateIdentificationExceptionResponse,
  IEditIdentificationExceptionData,
  IEditIdentificationExceptionResponse,
  IGetIdentificationExceptionsResponse,
  IIdentificationExceptionsRuleType,
} from '@/api/payment/models/identificationExceptions'
import { IPaymentDocument } from '@/models/entities/payment'
import {
  IPaymentIdentification,
  IPaymentIdentificationStatus,
} from '@/models/entities/paymentIdentification'

export const getIdentificationExceptionsRuleTypes = (): Promise<
  Record<string, IIdentificationExceptionsRuleType[]>
> => Promise.resolve({ list: identificationExceptionsRuleTypeList })

export const getRegisterPayments = (params?: unknown) => {
  return request<IResponseList<IPaymentDocument>>({
    url: '/api/v1/payment/register_payments',
    method: 'GET',
    params,
  })
}

export const getPaymentIdentificationStatus = (params?: unknown) => {
  return request<IResponseList<IPaymentIdentificationStatus>>({
    url: '/api/v1/payment/identification_status',
    method: 'GET',
    params,
  })
}

export const getPaymentIdentifications = (params?: unknown) => {
  return request<IResponseList<IPaymentIdentification>>({
    url: '/api/v1/payment/payment/identified_sum',
    method: 'GET',
    params,
  })
}

export const getIdentificationExceptions = (
  params?: unknown,
): Promise<IGetIdentificationExceptionsResponse> => {
  return request({
    url: '/api/v1/payment/identification_exception',
    method: 'GET',
    params,
  })
}

export const createIdentificationException = (
  data: ICreateIdentificationExceptionData,
): Promise<ICreateIdentificationExceptionResponse> => {
  return request({
    url: '/api/v1/payment/identification_exception',
    method: 'POST',
    data,
  })
}

export const editIdentificationException = (
  id: string | number,
  data: IEditIdentificationExceptionData,
): Promise<IEditIdentificationExceptionResponse> => {
  return request({
    url: `/api/v1/payment/identification_exception/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteIdentificationException = (id: number): Promise<unknown> => {
  return request({
    url: `/api/v1/payment/identification_exception`,
    method: 'DELETE',
    params: { id },
  })
}
