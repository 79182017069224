import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import api from '@/api'
import { get, set, isArray, isObject, cloneDeep } from 'lodash'
import { mapObject } from '@/utils/objectUtils'

export const useTranslationStore = defineStore('translation', () => {
  const languages = ref([])
  const isLanguagesLoading = ref(false)
  const selectedLanguageId = ref(null)
  const translations = ref(null)

  function fetchLanguages() {
    isLanguagesLoading.value = true
    return api.translation
      .getLanguages()
      .then(res => {
        languages.value = res.list || []
      })
      .catch(() => (languages.value = []))
      .finally(() => (isLanguagesLoading.value = false))
  }

  function selectLanguage(id) {
    if (!id) {
      selectedLanguageId.value = null
      return localStorage.removeItem('lang')
    }
    localStorage.setItem('lang', id)
    selectedLanguageId.value = id
  }

  function importLanguage() {
    const id = localStorage.getItem('lang')
    if (id) {
      selectedLanguageId.value = Number(id)
    }
  }

  const selectedLanguage = computed(() => {
    return languages.value.find(el => el.id === selectedLanguageId.value)
  })

  const refetchTranslations = () => {
    if (selectedLanguageId.value) {
      return api.translation
        .getTranslationsDb({ id: selectedLanguageId.value })
        .then(res => (translations.value = res.data?.translations))
        .catch(() => (translations.value = null))
    }
  }

  watch(selectedLanguageId, id => {
    if (id) {
      api.translation
        .getTranslationsDb({ id })
        .then(res => (translations.value = res.data?.translations))
        .catch(() => (translations.value = null))
    }
  })

  const translateObj = (obj, paths, createCopy = true) => {
    if (!isObject(obj) || !isArray(paths) || !translations.value) {
      return obj
    }

    let outObj = obj
    if (createCopy) {
      outObj = cloneDeep(obj)
    }
    for (const path of paths) {
      const value = get(outObj, path)
      if (!value) {
        continue
      }
      set(outObj, path, translations.value?.[value] || value)
    }

    return outObj
  }

  const translateString = (string = '') => {
    return translations.value?.[string] || string
  }

  const translateList = (arr, paths) => {
    if (!isArray(arr) || !isArray(paths)) {
      return arr
    }
    return arr.map(el => {
      const listElement = mapObject(el, v => translateList(v, paths))
      return translateObj(listElement, paths, true)
    })
  }

  const getTranslatedList = <T>(arr: T[], paths) => {
    return computed<T[]>(() => translateList(arr, paths))
  }

  return {
    languages,
    selectedLanguageId,
    selectedLanguage,
    selectLanguage,
    importLanguage,
    fetchLanguages,
    isLanguagesLoading,
    translations,
    refetchTranslations,
    getTranslatedList,
    translateString,
  }
})
