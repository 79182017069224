export default [
  {
    id: '1',
    name: 'Сеть Топаз',
  },
  {
    id: '2',
    name: 'Росинтер',
  },
]
