import { tableConfig } from '@/api/objectRegistry/mock'
import request, {
  IResponseEntity,
  IResponseList,
  IResponseListWithAggregations,
} from '@/api/request'
import { IUseobject, IUseobjectType } from '@/models/entities/useobject'
import { IUseobjectV2 } from '@/models/entities/useobjectV2'

export const getTableConfig = () => {
  return Promise.resolve(tableConfig)
}

export const getList = params => {
  return request({
    url: '/api/v1/useobject/useobjects',
    method: 'GET',
    params,
  })
}

export const searchUseobjects = (params?: unknown) => {
  return request<IResponseListWithAggregations<IUseobjectV2>>({
    url: '/api/v1/useobject/useobjects/search',
    method: 'GET',
    params,
  })
}

export const createPhysicalUseobject = data => {
  return request<IResponseEntity<IUseobjectV2>>({
    url: '/api/v1/useobject/useobjects/physical',
    method: 'POST',
    data,
  })
}

export const editPhysicalUseobject = (id, data) => {
  return request({
    url: `/api/v2/useobject/useobjects/physical/${id}`,
    method: 'PUT',
    data,
  })
}

export const createMediaUseobject = data => {
  return request({
    url: '/api/v1/useobject/useobjects/media',
    method: 'POST',
    data,
  })
}

export const editMediaUseobject = (id, data) => {
  return request({
    url: `/api/v2/useobject/useobjects/media/${id}`,
    method: 'PUT',
    data,
  })
}

export const getUseObject = id => {
  return request<IUseobject>({
    url: `/api/v1/useobject/useobjects/${id}`,
    method: 'GET',
  })
}

export const removeUseobject = (id: IUseobjectV2['id']) => {
  return request<IUseobjectV2>({
    url: `/api/v1/useobject/useobjects/${id}`,
    method: 'DELETE',
  })
}

export const getTypesForSelect = (params?: unknown) => {
  return request<IResponseList<IUseobjectType>>({
    url: `/api/v1/useobject/types`,
    method: 'GET',
    params,
  })
}

export const getClasses = () => {
  return request({
    url: `/api/v1/useobject/classes`,
    method: 'GET',
  })
}

export const getGroups = () => {
  return request({
    url: `/api/v1/useobject/external/groups`,
    method: 'GET',
  })
}

export const getUseobjectGroups = params => {
  return request({
    url: `/api/v1/useobject/useobject_groups`,
    method: 'GET',
    params,
  })
}

export const getUseobjectGroup = id => {
  return request({
    url: `/api/v1/useobject/useobject_groups/${id}`,
    method: 'GET',
  })
}

export const createUseobjectGroup = data => {
  return request({
    url: `/api/v1/useobject/useobject_groups`,
    method: 'POST',
    data,
  })
}

export const editUseobjectGroup = (id, data) => {
  return request({
    url: `/api/v1/useobject/useobject_groups/${id}`,
    method: 'PATCH',
    data,
  })
}

export const deleteUseobjectGroup = id => {
  return request({
    url: `/api/v1/useobject/useobject_groups/${id}`,
    method: 'DELETE',
  })
}

export const getUseobjectParameterRules = data => {
  return request({
    url: `/api/v1/useobject/usecategory_parameter_rules`,
    method: 'GET',
    params: data,
  })
}

export const getUseobjectParameterValues = data => {
  return request({
    url: `/api/v1/useobject/parameter_values`,
    method: 'GET',
    params: data,
  })
}

//TODO вернуть после переезда сервиса фин.характеристик
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const getUseobjectParameterTypes = data => {
  return Promise.resolve()
  /*return request({
    url: `/api/v1/useobject/parameter_types`,
    method: 'GET',
    params: data,
  })*/
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const getUseobjectParameterValueTypes = data => {
  return Promise.resolve()
  /*return request({
    url: `/api/v1/useobject/parameter_value_types`,
    method: 'GET',
    params: data,
  })*/
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const updateParameterValues = data => {
  return Promise.resolve()
  /*return request({
    url: `/api/v1/parameter_values/save`,
    method: 'PUT',
    data,
  })*/
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const getParameterValuesList = data => {
  return Promise.resolve()
  /* return request({
    url: `/api/v1/parameter_values/list`,
    method: 'GET',
    params: data,
  })*/
}

export const getUseobjectV2 = (id: string | number) => {
  return request<IResponseEntity<IUseobjectV2>>({
    url: `/api/v2/useobject/useobjects/${id}`,
  })
}
