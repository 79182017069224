export default [
  {
    id: '5',
    classId: '1',
    name: 'Здание (ТРЦ, Рынок)',
    level: '40',
    options: '{}',
  },
  {
    id: '6',
    classId: '1',
    name: 'Комплекс (из нескольких зданий)',
    level: '50',
    options: '{}',
  },
  {
    id: '4',
    classId: '1',
    name: 'Одиночный объект',
    level: '30',
    options: '{}',
  },
]
