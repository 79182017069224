import { contractedValue } from '@/utils/presetService'

export const DEFAULT_MIN_WIDTH = 50
export const DEFAULT_MAX_WIDTH = 800

export default function (col = {}) {
  const { label = '', field = '', presetParams = {}, widgetId, config = {}, sortFieldName } = col
  return {
    title: label,
    key: field,
    widgetId,
    sortFieldName,
    minWidth: `${DEFAULT_MIN_WIDTH}`,
    maxWidth: `${DEFAULT_MAX_WIDTH}`,
    ...presetParams?.options,
    hidden: contractedValue(config.prefs?.disabled),
    ellipsis: {
      tooltip: true,
    },
  }
}
