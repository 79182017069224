import request from '@/api/request'
import {
  IDocumentType,
  IGetDocumentTypeResponse,
  IGetDocumentTypesResponse,
} from '@/api/documentTypes/models'

export const getDocumentTypes = (params?: unknown): Promise<IGetDocumentTypesResponse> => {
  return request({
    url: `/api/v1/document/types/list`,
    method: 'GET',
    params,
  })
}

export const getDocumentType = (id: string): Promise<IGetDocumentTypeResponse> => {
  return request({
    url: `/api/v1/document/types/get/${id}`,
    method: 'GET',
  })
}

export const createDocumentType = (data: IDocumentType) => {
  return request({
    url: `/api/v1/document/types/create`,
    method: 'POST',
    data,
  })
}

export const editDocumentType = (id: string, data: IDocumentType) => {
  return request({
    url: `/api/v1/document/types/update/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteDocumentType = (id: string) => {
  return request({
    url: `/api/v1/document/types/delete`,
    method: 'DELETE',
    params: {
      id,
    },
  })
}
