import { type DataTableRowKey } from 'naive-ui'
import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  ILicense,
  ILicenseBroadcastGrid,
  ILicenseNote,
  ILicenseDifference,
  ILicenseStatus,
  ILicenseWork,
  IResponseKind,
  IVersionHandling,
} from '@/models/entities/license'
import { hasOrHasNotFilterValues } from '@/utils/baseHelpers'

export const getLicenses = (params?: unknown) => {
  return request<IResponseList<ILicense>>({
    url: '/api/v1/license/search/licenses',
    method: 'GET',
    params,
  })
}

export const getLicenseStatuses = (params?: unknown) => {
  return request<IResponseList<ILicenseStatus>>({
    url: '/api/v1/license/statuses',
    method: 'GET',
    params,
  })
}

export const getLicenseWorkStatuses = (params?: unknown) => {
  return request<IResponseList<ILicenseStatus>>({
    url: '/api/v1/license/work_statuses',
    method: 'GET',
    params,
  })
}

export const getLicenseHandlingStatuses = (params?: unknown) => {
  return request<IResponseList<IVersionHandling['handlingStatus']>>({
    url: '/api/v1/license/handling_statuses',
    method: 'GET',
    params,
  })
}

export const editLicenseHandlingStatuses = (
  id: string,
  data: { statusId: ILicenseStatus['id'] },
) => {
  return request<IResponseList<IVersionHandling['handlingStatus']>>({
    url: `/api/v1/license/version_handlings/${id}`,
    method: 'PUT',
    data,
  })
}

export const getLicenseWithContractFilterValues = () => {
  return Promise.resolve(hasOrHasNotFilterValues)
}

export const getLicenseBroadcastGrids = (params?: unknown) => {
  return request<IResponseList<ILicenseBroadcastGrid>>({
    url: '/api/v1/license/list/broadcast_grids',
    method: 'GET',
    params,
  })
}

export const getLicensesWorks = (params?: unknown) => {
  return request<IResponseList<ILicenseWork>>({
    url: '/api/v1/license/works',
    method: 'GET',
    params,
  })
}

export const createLicenseNote = (data: Pick<ILicenseNote, 'note'> | { licenseId: string }) => {
  return request({
    url: `/api/v1/license/notes`,
    method: 'POST',
    data,
  })
}

export const editLicenseNote = (data: ILicenseNote, id: string = '') => {
  return request({
    url: `/api/v1/license/notes${id}`,
    method: 'PUT',
    data,
  })
}

export const getLicensesResponseKinds = () => {
  return request<IResponseList<IResponseKind>>({
    url: '/api/v1/license/response_kinds',
    method: 'GET',
  })
}

export const createLicenseWork = (data: unknown) => {
  return request<IResponseEntity<ILicenseWork>>({
    url: `/api/v1/license/works`,
    method: 'POST',
    data,
  })
}

export const editLicenseWork = (data: unknown, id: DataTableRowKey = '') => {
  return request<IResponseEntity<ILicenseWork>>({
    url: `/api/v1/license/works/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteLicenseWork = (id: DataTableRowKey = '') => {
  return request({
    url: `/api/v1/license/works/${id}`,
    method: 'DELETE',
  })
}

export const getLicensesDifferences = (id: DataTableRowKey) => {
  return request<IResponseEntity<ILicenseDifference>>({
    url: `/api/v1/license/licenses/differences/${id}`,
    method: 'GET',
  })
}
