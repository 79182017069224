import request, { IResponseEntity, IResponseList } from '@/api/request'
import { tableConfig, tabsConfig, infoPaneFields } from './mock'
import {
  IContract,
  IContractNetwork,
  ICreateContractData,
  ICreateContractUseobjectData,
  IContractChain,
  IContractProjectUseobject,
  IcreateContractFromProjectData,
  IContractSearch,
  IGetSearchContractParams,
} from '@/models/entities/contract'

export const getTableConfig = () => {
  return Promise.resolve(tableConfig)
}

export const getTabsConfig = () => Promise.resolve(tabsConfig)

export const getTabsInfoPaneFields = () => Promise.resolve(infoPaneFields)

export const getContracts = (params?: unknown) => {
  return request<IResponseList<IContract>>({
    url: '/api/v1/contract/contracts',
    method: 'GET',
    params,
  })
}

export const getContract = (id: string) => {
  return request<IContract>({
    url: `/api/v1/contract/contracts/${id}`,
    method: 'GET',
  })
}

export const searchContracts = (params?: IGetSearchContractParams) => {
  return request<IResponseList<IContractSearch>>({
    url: `/api/v1/contract/contracts/search`,
    method: 'GET',
    params,
  })
}

export const createContract = (data: ICreateContractData) => {
  return request({
    url: '/api/v1/contract/contracts/create',
    method: 'POST',
    data,
  })
}

export const createContractFromProject = (data: IcreateContractFromProjectData) => {
  return request<IContract>({
    url: '/api/v1/contract/contracts/create_from_project',
    method: 'POST',
    data,
  })
}

export const getOrgUnits = data => {
  return request({
    url: '/api/v1/contract/org-units',
    method: 'GET',
    params: data,
  })
}

export const getContractStatuses = data => {
  return request({
    url: '/api/v1/contract/contract-statuses',
    method: 'GET',
    params: data,
  })
}

export const getContractNetworks = (id: string) => {
  return request<IResponseEntity<IContractNetwork>>({
    url: `/api/v1/contract/contracts/get_networks/${id}`,
    method: 'GET',
  })
}

export const getContractUseobjects = (params?: unknown) => {
  return request<IResponseList<IContractProjectUseobject>>({
    url: `/api/v1/contract/project_useobjects`,
    method: 'GET',
    params,
  })
}

export const createContractUseobject = (data: ICreateContractUseobjectData) => {
  return request({
    url: '/api/v1/contract/project_useobjects/create',
    method: 'POST',
    data,
  })
}

export const deleteContractUseobject = (id: string) => {
  return request({
    url: `/api/v1/contract/contract_useobjects/${id}`,
    method: 'DELETE',
  })
}

export const getContractChains = (params?: unknown) => {
  return request<IResponseList<IContractChain>>({
    url: '/api/v1/contract/chains/search',
    method: 'GET',
    params,
  })
}
