import { defineStore } from 'pinia'
import { ref } from 'vue'
import api from '@/api'

export const useUserSettingsStore = defineStore('settings', () => {
  const userSettings = ref(null)
  const isUserSettingsLoading = ref(false)

  const setUserSettings = settings => {
    userSettings.value = settings
  }

  const mergedSettings = newSettings => ({
    ...userSettings.value,
    ...newSettings,
  })

  function fetchUserSettings() {
    isUserSettingsLoading.value = true
    return api.settings
      .getSettings()
      .then(response => {
        const settings = response.payload ? JSON.parse(response.payload) : null
        setUserSettings(settings)
      })
      .catch(() => {})
      .finally(() => {
        isUserSettingsLoading.value = false
      })
  }

  function storeUserSettings(newSettings) {
    setUserSettings(mergedSettings(newSettings))
  }

  function saveUserSettings(newSettings) {
    return api.settings
      .setSettings({ payload: JSON.stringify(mergedSettings(newSettings)) })
      .then(() => {})
      .catch(() => {
        fetchUserSettings()
      })
  }

  return {
    userSettings,
    isUserSettingsLoading,
    fetchUserSettings,
    saveUserSettings,
    storeUserSettings,
  }
})
