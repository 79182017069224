import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useObjectRegistryStore } from '@/stores/objectRegistry'

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const objectRegistryStore = useObjectRegistryStore()

  const storesMap = {
    objectRegistry: objectRegistryStore,
  }

  const breadcrumb = ref([])
  const tempStore = ref({})

  const buildBreadcrumb = config => {
    breadcrumb.value = config.map(el => {
      let text = ''
      const options = el.text.options

      const type = el.text.type
      if (type === 'simple') {
        text = options.value
      }
      if (type === 'fromStore') {
        const [store, object] = options.path.split('/')

        text = computed(() => storesMap[store][object]?.[options.field])
      }

      if (type === 'fromTempStore') {
        text = computed(() => tempStore.value[options.field])
      }

      return {
        name: el.name,
        text,
        to: el.to,
      }
    })
  }

  // deprecated
  const patchBreadcrumbItem = payload => {
    breadcrumb.value = breadcrumb.value.map(el => {
      if (el.name !== payload.name) {
        return el
      }
      return { name: el.name, text: payload.text }
    })
  }

  const clearBreadcrumb = () => {
    breadcrumb.value = []
  }

  const patchTempStore = payload => {
    tempStore.value = { ...tempStore.value, ...payload }
  }

  const clearTempStore = () => {
    tempStore.value = {}
  }

  return {
    breadcrumb,
    buildBreadcrumb,
    patchBreadcrumbItem,
    clearBreadcrumb,

    tempStore,
    patchTempStore,
    clearTempStore,
  }
})
