import request, { IResponseEntity, IResponseList } from '@/api/request'
import { IDocument, IDocumentFileRelation } from '@/models/entities/document'

export const getDocuments = params => {
  return request<IResponseList<IDocument>>({
    url: `/api/v1/document/documents/create`,
    method: 'GET',
    params,
  })
}

export const getDocument = id => {
  return request<IResponseEntity<IDocument>>({
    url: `/api/v1/document/documents/get/${id}`,
    method: 'GET',
  })
}

export const createDocument = data => {
  return request<IResponseEntity<IDocument>>({
    url: `/api/v1/document/documents/create`,
    method: 'POST',
    data,
  })
}

export const editDocument = (id, data) => {
  return request<IResponseEntity<IDocument>>({
    url: `/api/v1/document/documents/update/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteDocument = id => {
  return request({
    url: `/api/v1/document/documents/delete/${id}`,
    method: 'DELETE',
  })
}

export const createFileRelation = data => {
  return request<IResponseEntity<IDocumentFileRelation>>({
    url: `/api/v1/document/files`,
    method: 'POST',
    data,
  })
}

export const deleteFileRelation = id => {
  return request({
    url: `/api/v1/document/files`,
    method: 'DELETE',
    params: { id },
  })
}
