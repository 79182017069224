import { tableConfig, errorRequiresAttentionFilterValues } from '@/api/paymentImport/mock'
import request from '@/api/request'
import {
  IGetPaymentImportPackagesResponse,
  IGetPaymentImportPackageStatusesResponse,
} from '@/api/paymentImport/models'

export const getTableConfig = () => Promise.resolve(tableConfig)

export const getPaymentImportErrorRequiresAttentions = () =>
  Promise.resolve(errorRequiresAttentionFilterValues)

export const getImportConfigurations = params => {
  return request({
    url: '/api/v1/payment-import/import_configurations',
    method: 'GET',
    params,
  })
}

export const deleteImportConfigurations = id => {
  return request({
    url: `/api/v1/payment-import/import_configurations/${id}`,
    method: 'DELETE',
  })
}

export const editImportConfiguration = (id, data) => {
  return request({
    url: `/api/v1/payment-import/import_configurations/${id}`,
    method: 'PUT',
    data,
  })
}

export const createImportConfiguration = data => {
  return request({
    url: '/api/v1/payment-import/import_configurations',
    method: 'POST',
    data,
  })
}

export const getImportTypes = params => {
  return request({
    url: '/api/v1/payment-import/import_types',
    method: 'GET',
    params,
  })
}

export const getImportConfiguration = id => {
  return request({
    url: `/api/v1/payment-import/import_configurations/${id}`,
    method: 'GET',
  })
}

export const getActualImportConfigurations = params => {
  return request({
    url: '/api/v1/payment-import/import_configurations/actual',
    method: 'GET',
    params,
  })
}

export const createImportPackage = data => {
  return request({
    url: '/api/v1/payment-imports/packages/create',
    method: 'POST',
    data,
  })
}

export const getImportPackages = (params?: unknown): Promise<IGetPaymentImportPackagesResponse> => {
  return request({
    url: `/api/v1/payment-import/packages`,
    method: 'GET',
    params,
  })
}

export const getPaymentImportPackageStatuses = (
  params?: unknown,
): Promise<IGetPaymentImportPackageStatusesResponse> => {
  return request({
    url: `/api/v1/payment-import/package_statuses`,
    method: 'GET',
    params,
  })
}
