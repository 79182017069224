import { defineStore } from 'pinia'
import { ref } from 'vue'
import api from '@/api'
import { deleteForwardRoute } from '@/utils/routerHelpers'

export const useAuthStore = defineStore('auth', () => {
  const currentProfile = ref(null)
  const contourConfiguration = ref(null)

  const setCurrentProfile = profile => {
    currentProfile.value = profile
  }

  const setContourConfiguration = profile => {
    contourConfiguration.value = profile
  }

  function makeLogin(requestData) {
    return api.auth.login(requestData)
  }

  function makeLogout() {
    return api.auth.logout().then(() => {
      setCurrentProfile(null)
      deleteForwardRoute()
    })
  }

  const isAppInitialazing = ref(false)

  return {
    makeLogin,
    makeLogout,
    setCurrentProfile,
    setContourConfiguration,
    currentProfile,
    contourConfiguration,
    isAppInitialazing,
  }
})
