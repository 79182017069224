export default [
  {
    id: '2',
    name: 'СМИ',
    disabled: true,
  },
  {
    id: '3',
    name: 'Условный (виртуальный)',
    disabled: true,
  },
  {
    id: '1',
    name: 'Физический',
  },
]
