import request, { IResponseEntity } from '@/api/request'
import { toFormData } from '@/utils/baseHelpers'
import { IFileDownloadResponse } from './models/index'
import { IFile } from '@/models/entities/file'

export const buildFileDownloadUrl = (fileId: string) => `/api/v1/file/download/${fileId}`

export const uploadFiles = data => {
  return request<IResponseEntity<IFile>>({
    url: '/api/v1/file/files',
    method: 'POST',
    data: toFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const downloadFile = (id: string): Promise<IFileDownloadResponse> => {
  return request({
    url: `/api/v1/file/download/${id}`,
    method: 'GET',
    responseType: 'blob',
  })
}
