export const tableConfig = {
  id: '',
  name: 'Реестр договоров',
  type: 'TablePreset',
  widgetId: 'contracts_table',
  dbField: null,
  dataFieldPath: null,
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
    presetName: null,
    presetParams: null,
  },
  children: [
    {
      id: '',
      name: 'РЦ',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_department',
      dbField: '',
      dataFieldPath: 'department.code',
      prefs: {
        label: 'РЦ',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnTagsPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Выберите',
            presetName: 'form.select.FormSelectBasicPreset',
            field: 'filters.departmentId.values',
            presetParams: {
              optionsProvider: 'DEPARTMENTS',
              options: null,
              optionsMapKey: 'code',
              multiple: true,
            },
            queryOperator: {
              field: 'filters.departmentId.op',
              value: 'in',
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_executor',
      dbField: '',
      dataFieldPath: 'executor.name',
      prefs: {
        label: 'Исполнитель',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: null,
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_useobject',
      dbField: '',
      dataFieldPath: 'useobject.name',
      prefs: {
        label: 'Фактическое название',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Введите',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.useobjectName.value',
            queryOperator: {
              field: 'filters.useobjectName.op',
              value: 'contains',
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_contract',
      dbField: '',
      dataFieldPath: 'contractNumber',
      prefs: {
        label: 'Договор',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnButtonsPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Введите',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.contractNumber.value',
            queryOperator: {
              field: 'filters.contractNumber.op',
              value: 'contains',
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_subject_name',
      dbField: '',
      dataFieldPath: 'subject.displayName',
      prefs: {
        label: 'Субъект',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Введите',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.subjectName.value',
            queryOperator: {
              field: 'filters.subjectName.op',
              value: 'contains',
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_subject_identifier',
      dbField: '',
      dataFieldPath: 'subject.identifierValue',
      prefs: {
        label: 'ИНН',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Введите',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.subjectIdentifier.value',
            queryOperator: {
              field: 'filters.subjectIdentifier.op',
              value: 'contains',
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_rate_sum',
      dbField: '',
      dataFieldPath: 'rate.sum',
      prefs: {
        label: 'Ставка',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          sort: true,
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_rate_percent',
      dbField: '',
      dataFieldPath: '',
      prefs: {
        label: '%',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: null,
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_contract_date',
      dbField: '',
      dataFieldPath: 'contractDate.value',
      prefs: {
        label: 'Заключено',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDatePreset',
        presetParams: null,
        options: {
          sort: true,
          filter: {
            label: 'Выберите',
            presetName: 'table.columns.filters.PresetTableColumnFilterDateRange',
            field: 'filters.contractDate.value',
            queryOperator: {
              field: 'filters.contractDate.op',
              value: ['gte', 'lte'],
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_date_beg',
      dbField: '',
      dataFieldPath: 'dateBeg.value',
      prefs: {
        label: 'Начало',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDatePreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Выберите',
            presetName: 'table.columns.filters.PresetTableColumnFilterDateRange',
            field: 'filters.dateBeg.value',
            queryOperator: {
              field: 'filters.dateBeg.op',
              value: ['gte', 'lte'],
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_date_end',
      dbField: '',
      dataFieldPath: 'dateEnd.value',
      prefs: {
        label: 'Конец',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDatePreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Выберите',
            presetName: 'table.columns.filters.PresetTableColumnFilterDateRange',
            field: 'filters.dateEnd.value',
            queryOperator: {
              field: 'filters.dateEnd.op',
              value: ['gte', 'lte'],
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_usecategory',
      dbField: '',
      dataFieldPath: 'usecategory.shortName',
      prefs: {
        label: 'Категория',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnTagsPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Выберите',
            presetName: 'form.select.FormSelectBasicPreset',
            field: 'filters.usecategoryId.values',
            presetParams: {
              optionsProvider: 'USECATEGORIES',
              options: null,
              optionsMapKey: 'shortName',
              multiple: true,
            },
            queryOperator: {
              field: 'filters.usecategoryId.op',
              value: 'in',
            },
          },
        },
      },
      children: [],
    },
    {
      id: '',
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'contracts_column_status_name',
      dbField: '',
      dataFieldPath: 'status.name',
      prefs: {
        label: 'Статус',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnTagsPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Выберите',
            presetName: 'form.select.FormSelectBasicPreset',
            field: 'filters.statusId.values',
            presetParams: {
              optionsProvider: 'CONTRACT_STATUSES',
              options: null,
              optionsMapKey: 'statusName',
              multiple: true,
            },
            queryOperator: {
              field: 'filters.statusId.op',
              value: 'in',
            },
          },
        },
      },
      children: [],
    },
  ],
}

export const tabsConfig = {
  tabs: [
    {
      label: 'Информация',
      id: 'contractInfo',
      presence: {
        disabled: true,
      },
    },
    {
      label: 'Сопоставление',
      id: 'comparison',
      presence: {
        disabled: true,
      },
    },
    {
      label: 'Отчеты',
      id: 'reports',
      presence: {
        disabled: true,
      },
    },
    {
      label: 'Работа с пользователями',
      id: 'workingWithUsers',
      presence: {
        disabled: true,
      },
    },
    {
      label: 'Правоохранительные органы',
      id: 'police',
      presence: {
        disabled: true,
      },
    },
    {
      label: 'Суды',
      id: 'law',
      presence: {
        disabled: true,
      },
    },
    {
      label: ' Вознаграждение',
      id: 'reward',
      presence: {
        disabled: true,
      },
    },
    {
      label: 'Плеер',
      id: 'fonmix',
      presence: {
        disabled: true,
      },
    },
  ],
  presence: {
    hidden: false,
  },
}

export const infoPaneFields = {
  contractInfo: [
    [
      {
        name: '',
        descriptions: [],
      },
    ],
  ],
}
