import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  IEditStageData,
  IPayloadCreateStageData,
  IStage,
  IStageSettings,
  IStageDetailed,
  IStageWarningEdit,
  IStageWarning,
  IStageFile,
} from '@/models/entities/stage'
import {
  IConflictUseobject,
  IConflictUseobjectSearch,
  ICreateConflictUseobjectData,
} from '@/models/entities/conflictUseobject'
import {
  IConflictCase,
  IConflictCaseDetailed,
  IConflictCaseType,
  ICreateConflictCase,
  IGetConflictCasesParams,
} from '@/models/entities/conflictCases'

import {
  IWayToFixation,
  IReasonForFailure,
  INecessaryControlKind,
  IPower,
  IMonitorReason,
  IPrintFileData,
  IPrintFile,
} from '@/models/entities/userDevelopment'
import {
  ICreateFixationData,
  IEditFixationData,
  IFixation,
  IFixationSearch,
  IFixationStatuses,
} from '@/models/entities/fixation'

export const getStageSettings = (params: unknown) => {
  return request<IResponseList<IStageSettings>>({
    url: `/api/v1/user_development/stage_settings/list`,
    method: 'GET',
    params,
  })
}

export const getStages = (params: unknown) => {
  return request<IResponseList<IStageDetailed>>({
    url: `/api/v1/user_development/stages`,
    method: 'GET',
    params,
  })
}

export const getStage = (id: IStage['id']) => {
  return request<IResponseEntity<IStageDetailed>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'GET',
  })
}

export const createStage = (data: IPayloadCreateStageData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages`,
    method: 'POST',
    data,
  })
}

export const editStage = (id: IStage['id'], data: IEditStageData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteStage = (id: IStage['id']) => {
  return request({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'DELETE',
  })
}

export const getStageFiles = (params?: unknown) => {
  return request<IResponseList<IStageFile>>({
    url: `/api/v1/user_development/files/detailed`,
    method: 'GET',
    params,
  })
}

export const getConflictUseobjects = (params: unknown) => {
  return request<IResponseList<IConflictUseobject>>({
    url: `/api/v1/user_development/conflict_useobjects`,
    method: 'GET',
    params,
  })
}

export const searchConflictUseobjects = (params: unknown) => {
  return request<IResponseList<IConflictUseobjectSearch>>({
    url: `/api/v1/user_development/conflict_useobjects/search`,
    method: 'GET',
    params,
  })
}

export const getConflictUseobject = (id: IConflictUseobject['id']) => {
  return request<IResponseEntity<IConflictUseobject>>({
    url: `/api/v1/user_development/conflict_useobjects/${id}`,
    method: 'GET',
  })
}

export const searchConflictUseobjectStatuses = (params?: unknown) => {
  return request<IResponseList<unknown>>({
    url: `/api/v1/user_development/conflict_useobject/statuses/detailed`,
    params,
  })
}

export const createConflictUseobject = (data: ICreateConflictUseobjectData) => {
  return request({
    url: `/api/v1/user_development/conflict_useobjects`,
    method: 'POST',
    data,
  })
}

export const deleteConflictUseobject = (id: string) => {
  return request({
    url: `/api/v1/user_development/conflict_useobjects/${id}`,
    method: 'DELETE',
  })
}

export const deleteUserDevelopmentFile = (id: string) => {
  return request({
    url: `/api/v1/user_development/files/${id}/delete`,
    method: 'DELETE',
  })
}

export const getFixations = (params: unknown) => {
  return request<IResponseList<IFixationSearch>>({
    url: `/api/v1/user_development/fixations/search`,
    method: 'GET',
    params,
  })
}

export const getFixation = (id: IFixation['id']) => {
  return request<IResponseEntity<IFixation>>({
    url: `/api/v1/user_development/fixations/${id}`,
    method: 'GET',
  })
}

export const getConflictCase = (id: string | number) => {
  return request<IResponseEntity<IConflictCaseDetailed>>({
    url: `/api/v1/user_development/id/conflict_cases?id=${id}`,
    method: 'GET',
  })
}

export const editWarning = (id: IStageWarning['id'], data: IStageWarningEdit) => {
  return request<IResponseEntity<IStageWarning>>({
    url: `/api/v1/user_development/warnings/${id}`,
    method: 'PUT',
    data,
  })
}

export const createConflictCase = (data: ICreateConflictCase) => {
  return request({
    url: `/api/v1/user_development/conflict_cases/create`,
    method: 'POST',
    data,
  })
}

export const getConflictCasesSearch = (params?: IGetConflictCasesParams) => {
  return request<IResponseList<IConflictCase>>({
    url: `/api/v1/user_development/conflict_cases/search`,
    method: 'GET',
    params,
  })
}

export const getConflictCaseTypes = (params?: unknown) => {
  return request<IResponseList<IConflictCaseType>>({
    url: `/api/v1/user_development/conflict_cases/types`,
    params,
  })
}

export const getConflictCaseStatuses = (params?: unknown) => {
  return request<IResponseList<IConflictCase['status']>>({
    url: `/api/v1/user_development/conflict_cases/statuses`,
    params,
  })
}

export const createFixation = (data: ICreateFixationData) => {
  return request({
    url: `/api/v1/user_development/fixations/create`,
    method: 'POST',
    data,
  })
}

export const getFixationStatuses = (params: unknown) => {
  return request<IResponseList<IFixationStatuses>>({
    url: '/api/v1/user_development/fixations/statuses',
    method: 'GET',
    params,
  })
}

export const editFixation = (id: IFixation['id'], data: IEditFixationData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/fixations/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const getReasonsForFailure = (params: unknown) => {
  return request<IResponseList<IReasonForFailure>>({
    url: `/api/v1/user_development/reasons_for_failures`,
    method: 'GET',
    params,
  })
}

export const getNecessaryControlKinds = (params: unknown) => {
  return request<IResponseList<INecessaryControlKind>>({
    url: `/api/v1/user_development/necessary_control_kinds`,
    method: 'GET',
    params,
  })
}

export const getWaysToFixations = (params: unknown) => {
  return request<IResponseList<IWayToFixation>>({
    url: `/api/v1/user_development/ways_to_fixations`,
    method: 'GET',
    params,
  })
}

export const printFile = (data: Partial<IPrintFileData>) => {
  return request<IPrintFile>({
    url: `/api/v1/user_development/print`,
    method: 'PUT',
    data,
  })
}

export const getPowers = (params: unknown) => {
  return request<IResponseList<IPower>>({
    url: `/api/v1/user_development/powers`,
    method: 'GET',
    params,
  })
}

export const getMonitorReasons = (params: unknown) => {
  return request<IResponseList<IMonitorReason>>({
    url: `/api/v1/user_development/monitor_reasons`,
    method: 'GET',
    params,
  })
}
