import request, { IResponseEntity } from '@/api/request'
import { IAvailableElement, IAvailableElementData } from '@/api/form/models'

export const getAvailableElements = (data: IAvailableElementData) => {
  return request<IResponseEntity<IAvailableElement>>({
    url: '/api/v1/form/elements/available_elements',
    method: 'POST',
    data,
  })
}
