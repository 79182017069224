import request from '@/api/request'
import { IGetPositionTypesResponse } from '@/api/positionTypes/models'

export const getPositionTypes = (data?: unknown): Promise<IGetPositionTypesResponse> => {
  return request({
    url: `/api/v1/contact/position_types`,
    method: 'GET',
    params: data,
    data: null,
  })
}
