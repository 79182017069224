export const OBJECT_CLASSES = {
  PHYSICAL: '1',
  SMI: '2',
  VIRTUAL: '3',
}
export const OBJECT_TYPES = {
  SOUND_ZONE: 1,
  VIRTUAL: 2,
  SMI: 3,
  SINGLE_OBJECT: 4,
  SINGLE_BULIDING: 5,
  COMPLEX: 6,
}

export const OBJECT_GROUP_TYPES = {
  NETWORK: '1',
}
