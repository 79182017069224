import { IConfigElement, CONFIG_ELEMENT_TYPES } from '@/models/preset'

export const tableConfig: IConfigElement<CONFIG_ELEMENT_TYPES.TablePreset> = {
  id: '2',
  name: 'Реестр объектов',
  type: 'TablePreset',
  widgetId: 'objects_table',
  dbField: null,
  dataFieldPath: null,
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
    presetName: null,
    presetParams: null,
  },
  children: [
    {
      id: '',
      dataFieldPath: 'id',
      dbField: null,
      name: 'Колонка Код',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_id',
      prefs: {
        label: 'Код',
        hidden: 'false',
        disabled: 'true',
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          width: '100px',
        },
      },
      children: [],
    },
    {
      id: '',
      dataFieldPath: 'name',
      dbField: null,
      name: 'Колонка Наименование',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_name',
      prefs: {
        label: 'Наименование',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Поиск по имени',
            presetName: 'form.text.FormTextPlainPreset',
            field: 'filters.name.value',
            queryOperator: {
              field: 'filters.name.op',
              value: 'contains',
            },
            debounce: true,
          },
        },
      },
      children: [],
    },
    {
      id: '',
      dataFieldPath: 'usecategory.shortName',
      dbField: null,
      name: 'Колонка Категория',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_usecategory_shortName',
      prefs: {
        label: 'Категория',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnTagsPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Категория',
            presetName: 'form.select.FormSelectBasicPreset',
            field: 'filters.usecategoryId.values',
            queryOperator: {
              field: 'filters.usecategoryId.op',
              value: 'in',
            },
            presetParams: {
              optionsProvider: 'OBJECT_CATEGORIES',
              options: null,
              naiveProps: {
                consistentMenuWidth: false,
              },
              filterable: true,
              multiple: true,
            },
            debounce: false,
          },
          width: '200',
        },
      },
      children: [],
    },
    {
      id: '',
      dataFieldPath: 'address.fullAddress',
      dbField: null,
      name: 'Колонка Адрес',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_fullAddress',
      prefs: {
        label: 'Адрес',
        hidden: 'false',
        disabled: null,
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          filter: {
            label: 'Адрес',
            presetName: 'custom.form.select.SelectSuggestAddress',
            field: 'filters.addressId.values',
            queryOperator: {
              field: 'filters.addressId.op',
              value: 'in',
            },
            presetParams: {
              optionsProvider: null,
              options: null,
              naiveProps: {
                consistentMenuWidth: false,
              },
            },
          },
          width: '300',
        },
      },
      children: [],
    },
    {
      id: '',
      dataFieldPath: 'type.name',
      dbField: null,
      name: 'Колонка Тип',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_type',
      prefs: {
        label: 'Тип',
        hidden: 'false',
        disabled: 'true',
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {
          width: '180px',
        },
      },
      children: [],
    },
    {
      id: '',
      dataFieldPath: 'network.name',
      dbField: null,
      name: 'Колонка Сеть',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_groups',
      prefs: {
        label: 'Сеть',
        hidden: 'false',
        disabled: 'true',
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {},
      },
      children: [],
    },
    {
      id: '',
      dataFieldPath: 'address.region',
      dbField: null,
      name: 'Колонка Субъект РФ',
      type: 'TableColumnPreset',
      widgetId: 'objects_table_column_addressRegion',
      prefs: {
        label: 'Субъект РФ',
        hidden: 'false',
        disabled: 'true',
        editable: null,
        presetName: 'table.columns.TableColumnDefaultPreset',
        presetParams: null,
        options: {},
      },
      children: [],
    },
  ],
}
