import request from '@/api/request'
import { IGetPositionsResponse } from './models'

export const getPositions = (data?: unknown): Promise<IGetPositionsResponse> => {
  return request({
    url: `/api/v1/contact/positions`,
    method: 'GET',
    params: data,
    data: null,
  })
}

export const deletePosition = id => {
  return request({
    url: `/api/v1/contact/positions/${id}`,
    method: 'DELETE',
    data: null,
  })
}

export const createPosition = data => {
  return request({
    url: `/api/v1/contact/positions`,
    method: 'POST',
    data,
  })
}

export const updatePosition = (id, data) => {
  return request({
    url: `/api/v1/contact/positions/${id}`,
    method: 'PUT',
    data,
  })
}
