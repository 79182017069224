import request from '@/api/request'

export const getLanguages = params => {
  return request({
    url: '/api/v1/translation/languages',
    method: 'GET',
    params,
  })
}

export const getTranslationsDb = params => {
  return request({
    url: '/api/v1/translation/translations-db',
    method: 'GET',
    params,
  })
}

export const getTranslations = params => {
  return request({
    url: '/api/v1/translation/translations',
    method: 'GET',
    params,
  })
}

export const createTranslation = data => {
  return request({
    url: '/api/v1/translation/translations',
    method: 'POST',
    data,
  })
}

export const getTranslationsUntranslated = params => {
  return request({
    url: '/api/v1/translation/translations/untranslated',
    method: 'GET',
    params,
  })
}

export const getTokens = () => {
  return request({
    url: '/api/v1/translation/tokens',
    method: 'GET',
  })
}

export const createToken = data => {
  return request({
    url: '/api/v1/translation/tokens',
    method: 'POST',
    data,
  })
}

export const updateToken = (data, id) => {
  return request({
    url: `/api/v1/translation/tokens/${id}`,
    method: 'PUT',
    data,
  })
}
