import { IConfigElementSimple } from '@/models/preset'

const departmentsAteTabTest: IConfigElementSimple = {
  name: 'Контейнер таба Ограничения по АТЕ',
  type: 'ContainerPreset',
  widgetId: 'departments_tabs_departmentsAte_container',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
    options: {
      styles: {
        flex: 'true',
        flexDirection: 'column',
        gap: 10,
        minWidth: '1000px',
        width: '100%',
      },
    },
  },
  children: [
    {
      name: 'Заголовок блока тэгов Ограничения по АТЕ',
      type: 'TextPreset',
      widgetId: 'departments_tabs_departmentsAte_container_tags_title',
      prefs: {
        label: 'АТЕ',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          bold: true,
        },
      },
      children: [],
    },
    {
      name: 'блок тэгов таба Ограничения по АТЕ',
      type: 'ContainerPreset',
      widgetId: 'departments_tabs_departmentsAte_container_tags',
      prefs: {
        label: 'АТЕ',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          state: {
            inject: {
              data: 'ateData',
            },
          },
          styles: {
            gap: 0,
          },
        },
      },
      children: [],
    },
  ],
}

export default departmentsAteTabTest
