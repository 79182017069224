import request, { IResponseEntity, IResponseList } from '@/api/request'
import { IContour } from '@/models/entities/contour'

export const getContours = (params: unknown = {}) => {
  return request<IResponseList<IContour>>({
    url: `/api/v2/contour/contours`,
    method: 'GET',
    params,
  })
}

export const getContour = (id = '') => {
  return request<IResponseEntity<IContour>>({
    url: `/api/v2/contour/contours/${id}`,
    method: 'GET',
  })
}

export const createContour = data => {
  return request({
    url: `/api/v2/contour/contours`,
    method: 'POST',
    data,
  })
}

export const editContour = (id, data) => {
  return request({
    url: `/api/v2/contour/contours/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteContour = id => {
  return request({
    url: `/api/v2/contour/contours/${id}`,
    method: 'DELETE',
  })
}
