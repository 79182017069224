import request from '@/api/request'
import {
  IGetIdentificationAlgorithmsResponse,
  IGetIdentificationConfigurationsResponse,
  IIdentificationConfigurationData,
} from '@/api/identificationConfigurations/models'

export const getAlgorithms = (params?: unknown): Promise<IGetIdentificationAlgorithmsResponse> => {
  return request({
    url: '/api/v1/payment/identification_algorithm',
    method: 'GET',
    params,
  })
}

export const getConfigurations = (
  params?: unknown,
): Promise<IGetIdentificationConfigurationsResponse> => {
  return request({
    url: '/api/v1/payment/identification_configuration/external',
    method: 'GET',
    params,
  })
}

export const addConfiguration = (data: IIdentificationConfigurationData) => {
  return request({
    url: `/api/v1/payment/identification_configuration`,
    method: 'POST',
    data,
  })
}

export const editConfiguration = (id: string | number, data: IIdentificationConfigurationData) => {
  return request({
    url: `/api/v1/payment/identification_configuration/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteConfiguration = id => {
  return request({
    url: `/api/v1/payment/identification_configuration/${id}`,
    method: 'DELETE',
  })
}
