import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { useNotification } from '@/composables/useNotification'
import router from '@/router'
import { saveForwardRoute } from '@/utils/routerHelpers'
import { useTranslationStore } from '@/stores/translation'
import { Nullable } from '@/models/helpers'

export interface IResponseList<T> {
  list: T[]
  count: number
  filters: Nullable<Record<string, unknown>>
}

export interface IAggregation {
  items: IAggregationItem[]
}

export interface IAggregationItem {
  key: string
  value: string
  count: string
}
export interface IResponseListWithAggregations<T> extends IResponseList<T> {
  aggregations?: {
    aggregations: Record<string, IAggregation>
  }
}

export interface IResponseEntity<T> {
  data: T
}

const showResponseErrorMessage = (message = 'Unknown server error') => {
  const translationStore = useTranslationStore()
  useNotification({
    type: 'error',
    message: translationStore.translateString(message),
  })
}

axios.interceptors.response.use(
  response => {
    if (!response) return {}
    return response
  },
  error => {
    if (error.response?.status === 401) {
      saveForwardRoute(router.currentRoute.value)

      router.push('/login')
      return Promise.reject(error)
    }
    if (!error.request?.responseURL?.includes('/api/v1/form/elements/available_elements')) {
      showResponseErrorMessage(error?.response?.data?.message)
    }
    return Promise.reject(error)
  },
)

export default function request<T = unknown>({
  method = 'GET',
  url,
  data = {},
  params = {},
  headers = {},
  responseType = 'json',
}: AxiosRequestConfig): Promise<T> {
  return axios({
    method,
    url,
    data,
    headers,
    params,
    responseType,
    withCredentials: true,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false })
    },
  }).then(response => response.data)
}
