export const CLASSES_TYPES = {
  PHYSICAL: 1,
  SMI: 2,
  CONDITIONAL: 3,
}

export const CLASSES_LABELS = {
  [CLASSES_TYPES.PHYSICAL]: 'Физический',
  [CLASSES_TYPES.SMI]: 'СМИ',
  [CLASSES_TYPES.CONDITIONAL]: 'Условный',
}
