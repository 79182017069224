import { tableConfig } from './mock'
import request from '@/api/request'

export const getTableConfig = () => {
  return Promise.resolve(tableConfig)
}

export const getCurrencies = params => {
  return request({
    url: '/api/v3/dictionary/currencies',
    method: 'GET',
    params,
  })
}
