export const composeObject = (object, iterator = val => val, keys = Object.keys(object)) =>
  keys.reduce((acc, field) => ({ ...acc, ...iterator(object[field], field) }), {})

export const filterObject = (object, callback) =>
  composeObject(object, (val, key) => (callback(val, key) ? { [key]: val } : {}))

export const mapObject = (object, callback) =>
  composeObject(object, (val, key) => ({ [key]: callback(val, key) }))

export function walkObject(source, mapper = (v, k) => ({ [k]: v })) {
  if (typeof source !== 'object' || source === null || source === undefined) {
    return source
  }

  if (Array.isArray(source)) {
    return source.map((v, k) => mapper(walkObject(v, mapper), k, false))
  }

  return Object.keys(source).reduce(
    (acc, key) => ({
      ...acc,
      ...mapper(walkObject(source[key], mapper), key, source),
    }),
    {},
  )
}

export function walkObjectRecursive(source, mapper = (v, k) => ({ [k]: v })) {
  if (typeof source !== 'object' || source === null || source === undefined) {
    return source
  }

  if (Array.isArray(source)) {
    return source.reduce(
      (acc, val, index) => [...acc, mapper(walkObjectRecursive(val, mapper), index)],
      [],
    )
  }

  return Object.keys(source).reduce(
    (acc, key) => ({
      ...acc,
      ...mapper(walkObjectRecursive(source[key], mapper), key),
    }),
    {},
  )
}

export const raw = value => JSON.parse(JSON.stringify(value))
