import { INetSourceType } from '@/models/entities/netSourceType'
import request, { IResponseEntity, IResponseList } from '../request'
import { INetSource } from '@/models/entities/netSource'
import { ICreateNetSourcePayload } from './models'

export const getNetSources = (params?: unknown) => {
  return request<IResponseList<INetSource>>({
    url: '/api/v1/useobject/net_sources',
    params,
  })
}

export const createNetSource = (data: ICreateNetSourcePayload) => {
  return request<IResponseEntity<INetSourceType>>({
    url: '/api/v1/useobject/net_sources',
    method: 'POST',
    data,
  })
}

export const updateNetSource = (id: INetSourceType['id'], params: ICreateNetSourcePayload) => {
  return request<IResponseEntity<INetSource>>({
    url: `/api/v1/useobject/net_sources/${id}`,
    method: 'PUT',
    params,
  })
}

export const deleteNetSource = (id: INetSourceType['id']) => {
  return request<IResponseEntity<INetSource>>({
    url: `/api/v1/useobject/net_sources`,
    method: 'DELETE',
    params: { id },
  })
}
