export default [
  {
    id: '1',
    name: '1.5',
  },
  {
    id: '2',
    name: '2.5',
  },
]
