import { changeNumericValueType } from '@/utils/baseHelpers'
export interface IOptions {
  label: string
  value: string | number
}

interface IMapperOptions {
  isConvertIdToNumber?: boolean
}

export function mapOptions(
  list: unknown[] = [],
  labelMapKey: string = 'name',
  labelMapValue: string = 'id',
  mapperOptions?: IMapperOptions,
): IOptions[] {
  let isConvertIdToNumber = mapperOptions?.isConvertIdToNumber
  return list.map(item => {
    return {
      label: item[labelMapKey],
      value: isConvertIdToNumber
        ? changeNumericValueType(item[labelMapValue])
        : item[labelMapValue],
    }
  })
}

export function mapOptionsWithDescription(
  list: unknown[] = [],
  labelMapKeys: unknown[] = [],
  mapperOptions?: IMapperOptions,
) {
  let isConvertIdToNumber = mapperOptions?.isConvertIdToNumber
  return list.map(item => {
    const [name, description] = labelMapKeys
    return {
      label: `${item[name] || '-'} (${item[description] || '-'})`,
      value: isConvertIdToNumber ? changeNumericValueType(item.id) : item.id,
    }
  })
}

export function mapSelectOptions(dictionary) {
  return Object.entries(dictionary).map(v => ({
    label: v[1],
    value: v[0],
  }))
}

export function sortListAlphabetically(list = [], key = 'label') {
  return list?.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()))
}

export function mapFixationFiles(files) {
  if (!files) return []
  return files.map(fileData => {
    return {
      fileId: fileData.fileId,
      name: fileData.file.name,
      id: fileData.id,
    }
  })
}
