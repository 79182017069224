import { IConfigElementSimple } from '@/models/preset'

export const addressesTab: IConfigElementSimple = {
  name: 'Таблица таба Расчётные счета',
  type: 'TablePreset',
  widgetId: 'departments_tabs_addresses_table',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
    options: null,
  },
  children: [
    {
      name: 'колонка Тип адреса',
      type: 'TableColumnPreset',
      widgetId: 'departments_tabs_addresses_table_type',
      prefs: {
        label: 'Тип адреса',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          width: '260px',
        },
      },
      children: [],
    },
    {
      name: 'колонка Адрес',
      type: 'TableColumnPreset',
      widgetId: 'departments_tabs_addresses_table_address',
      prefs: {
        label: 'Адрес',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          width: '560px',
        },
      },
      children: [],
    },
    {
      name: 'колонка Основной',
      type: 'TableColumnPreset',
      widgetId: 'departments_tabs_addresses_table_isMain',
      prefs: {
        label: 'Основной',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          width: '110px',
        },
      },
      children: [],
    },
    {
      name: 'колонка Примечание',
      type: 'TableColumnPreset',
      widgetId: 'departments_tabs_addresses_table_note',
      prefs: {
        label: 'Примечание',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          width: '110px',
        },
      },
      children: [],
    },
    {
      name: '',
      type: 'TableColumnPreset',
      widgetId: 'departments_tabs_addresses_table_actions',
      prefs: {
        label: '',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          rowControls: {
            isEditable: 'true',
            isDeletable: 'true',
          },
          minWidth: '80px',
        },
      },
      children: [],
    },
  ],
}
