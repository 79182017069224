import request, { IResponseEntity } from '@/api/request'
import { IBank } from '@/models/entities/bank'

export const createBank = data => {
  return request<IResponseEntity<IBank>>({
    url: '/api/v3/banks/new',
    method: 'POST',
    data,
  })
}

export const editBank = (id, data) => {
  return request<IResponseEntity<IBank>>({
    url: `/api/v1/banks/constraint/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteBank = id => {
  return request({
    url: `/api/v1/banks/soft`,
    method: 'DELETE',
    params: { id },
  })
}

export const getBankSuggestions = data => {
  return request({
    url: '/api/v1/banks/suggest',
    method: 'GET',
    params: data,
  })
}

export const getBankStatuses = () => {
  return request({
    url: '/api/v1/dictionary/bank_statuses',
    method: 'GET',
  })
}
