import { type RouteLocationNormalized } from 'vue-router'

type UnsavedPages = string | symbol
/**
 * Массив наименований путей, которые не должны быть сохранены
 * @type {string[]}
 */
const UNSAVED_PAGES: UnsavedPages[] = ['Profiles', 'Login', 'Confirmation']

//Сохраняет путь для редиректа после авторизации
export const saveForwardRoute = (route: RouteLocationNormalized) => {
  const { path, params, query, hash, name } = route
  const forwardRoute = { path, params, query, hash, name }

  if (UNSAVED_PAGES.includes(forwardRoute.name)) return

  const serializedForwardRoute = JSON.stringify(forwardRoute)
  sessionStorage.setItem('forwardRoute', serializedForwardRoute)
}

export const deleteForwardRoute = () => {
  sessionStorage.removeItem('forwardRoute')
}

export const getForwardRoute = () => {
  return JSON.parse(sessionStorage.getItem('forwardRoute'))
}
