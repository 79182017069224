import request from '@/api/request'

export const getSettings = () => {
  return request({
    url: `/api/v1/settings`,
    method: 'GET',
  })
}

export const setSettings = data => {
  return request({
    url: `/api/v1/settings`,
    method: 'PUT',
    data,
  })
}
